import React from "react";
import {
  Box,
  TextField,
  Button,
  Input,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import Farmers_img from "../../assets/FarmersLife_Homepage.png";
import checkedIcon from "../../assets/checkedIcon.png";
import unCheckedIcon from "../../assets/unCheckedIcon.png";
import "./calculator.css";
import { useState, useContext } from "react";
// import { Line } from "react-chartjs-2";
import { useEffect } from "react";
import { StateAPI } from "../../service/stateList.service";
import { YearListAPI } from "../../service/year.service";
import { NewSaveDataAPI } from "../../service/savedata.service";
import success_dialog from "../../assets/success_dialog.png";
import { useNavigate } from "react-router-dom";
import { DownloadPdfAPI } from "../../service/download_pdf.service";
import { Context } from "../../context/userLoaderContext";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import { Footer } from "../../components/footer/footer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label
} from "recharts";

const Calculator = (props) => {

  const [periodValue, setPeriodValue] = useState(0);
  const [periodValueObj, setPeriodValueObj] = useState([{}]);
  const [interstRate, setInterstRate] = useState(0);
  const [interstRate1, setInterstRate1] = useState(0);
  const [year_list_id, setYear_list_id] = useState();
  const [state_id, setStateId] = useState();
  const [state_Name, setStateName] = useState();
  const [amount, setAmount] = useState();
  const [age, setAge] = useState([]);
  const [name, setName] = useState("");
  const [stateList, setStateList] = useState([{}]);
  const [amountText, setAmountText] = useState(0);
  const [surrenderCharge, setSurrenderCharge] = useState([[]]);
  const [surrender_charge_array, setSurrender_charge_array] = useState([]);
  const [final_value, setFinal_value] = useState();
  const [freeWithdrawalRider, setFreeWithdrawalRider] = useState(false);
  const [enhancedBenefitRider, setEnhancedBenefitRider] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);
  const [finalChartData, setFinalChartData] = useState();
  const [finalMonthlyChartData, setFinalMonthlyChartData] = useState(null);
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [creditSum, setIntCreditSum] = useState(0);
  const [wdSum, setWdSum] = useState(0);
  const [msg, setMsg] = useState({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([{}]);
  const [flagWithdrawInterest, setFlagWithdrawInterest] = useState(true);
  const [prepardBy, setPrepardBy] = useState("");
  const { state, SetFlagLoader } = useContext(Context);
  console.log(state.user.first_name + " " + state.user.last_name);
  const queryParams = new URLSearchParams(window.location.search);
  const calculation_id = queryParams.get("calculation_id");
  const addCommas = (num) => {
    if (num) {
      return num.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  };

  const downloadBase64File = (contentBase64, fileName) => {
    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const removeNonNumeric = (num) => num.toString()?.replace(/[^0-9]/g, "");

  useEffect(() => {
    SetFlagLoader(false);
    StateAPI(
      (res) => {
        if (res.data.status === true) {
          res.data.data.map((rr) => {
            rr["label"] = rr["state_name"];
          });
          setStateList(res.data.data);
        } else {
          console.log("res.data.status false");
        }
      },
      (err) => {
        console.log("err", err);
      }
    );

    YearListAPI((res) => {
      if (res.data.status == true) {
        setPeriodValueObj(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setPeriodValue(0);
    setStateName("");
    setYear_list_id();
    setStateId();
    setAmount();
    setInterstRate(0);
    setAmount(0);
    setAge([]);
    setName("");
    setPrepardBy("");
    setEnhancedBenefitRider(false);
    setFreeWithdrawalRider(false);
    setFinal_value();
    setAmountText(null);
    setSurrenderCharge([[]]);
    setSurrender_charge_array([]);
  }, [open]);

  useEffect(() => {
    let globalTemp = Number(amount);
    let FinalArr = [];
    let chartDataArr = [];
    let tempInterestRate = Number(interstRate1);
    if (freeWithdrawalRider && enhancedBenefitRider) {
      tempInterestRate -= 0.002;
    } else if (freeWithdrawalRider || enhancedBenefitRider) {
      tempInterestRate -= 0.001;
    }
    setInterstRate(tempInterestRate);

    if (!flagWithdrawInterest) {
      chartDataArr.push({
        name: `Y 0`,
        amount: globalTemp
      });
    }

    let sumCredit = 0;
    let sumWd = 0;
    for (let i = 0; i < periodValue; i++) {
      const amountTextNumber = Number(amountText?.replace(/,/g, "")) || 0;
      const interestCredit = globalTemp * tempInterestRate;
      const newAccountValue = Number(globalTemp + interestCredit);
      const surrenderValue = Number(newAccountValue * (1 - surrender_charge_array[i]));

      setFinal_value(newAccountValue.toFixed(0));

      if (flagWithdrawInterest) {
        sumCredit += interestCredit;
        sumWd += interestCredit;
        FinalArr.push([
          tempInterestRate,
          interestCredit,
          interestCredit,
          globalTemp,
          surrenderValue
        ]);
        chartDataArr.push({
          name: `Y ${i + 1}`,
          amount: Number(globalTemp.toFixed(2))
        });
      } else {
        sumCredit += interestCredit;
        sumWd += 0;
        FinalArr.push([
          tempInterestRate,
          interestCredit,
          0,
          newAccountValue,
          surrenderValue
        ]);
        chartDataArr.push({
          name: `Y ${i + 1}`,
          amount: Number(newAccountValue.toFixed(2))
        });
      }

      globalTemp = newAccountValue;
    }
    setFinalChartData(chartDataArr);
    setSurrenderCharge(FinalArr);
    setIntCreditSum(sumCredit);
    setWdSum(sumWd);
  }, [amount, periodValue, freeWithdrawalRider, enhancedBenefitRider, flagWithdrawInterest]);

  function formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }

  function getFirstDate() {
    const today = new Date();
    let firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    if (today.getDate() > 7) {
      firstDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    }
    return formatDate(firstDate);
  }

  const getIssuedate = () => {
    const today = new Date();
    let firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    if (today.getDate() > 7) {
      firstDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    }

    const month = firstDate.getMonth() + 1;
    const day = firstDate.getDate();
    const year = firstDate.getFullYear();
    return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
  }

  const GetNextDate = (inputDate) => {
    const parts = inputDate.split('/');
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    date.setMonth(date.getMonth() + 1);
    return formatDate(date);
  }

  const GetAvBeforeWd = (month, date, prevDate, preAvAfterWd) => {
    if (month == 0) {
      return Number(amountText?.replace(/,/gi, ""))
    } else {
      let diff = preAvAfterWd * Math.pow((1 + interstRate), (daysBetweenDates(prevDate, date) / 365))
      return diff
    }
  }

  useEffect(() => {
    if (periodValue && amountText?.length) {
      const periodValueNum = Number(periodValue)
      const amountTextNumber = amountText?.replace(/,/gi, "")
      let finalMonthArr = [{
        month: 0,
        date: getFirstDate(),
        avBeforeWd: Number(amountTextNumber),
        intCredit: 0,
        wd: 0,
        avAfterWd: Number(amountTextNumber),
        cashFlow: 0 - Number(amountTextNumber),
        flagMax: false,
        flagMin: false
      }]

      let crrentDate;
      let currentAvBeforeWd;
      let currentIntCredit;
      let currentCash;
      let monthlyLabels = ["year 0"];
      let graphPoints = [0];
      let monthlyGraphDataArr = []
      monthlyGraphDataArr.push({
        name: 0,
        amount: 0
      })

      // let tempInterestRate = interstRate;
      // if (freeWithdrawalRider && enhancedBenefitRider) {
      //   tempInterestRate -= 0.002;
      // } else if (freeWithdrawalRider) {
      //   tempInterestRate -= 0.001;
      // } else if (enhancedBenefitRider) {
      //   tempInterestRate -= 0.001;
      // }

      for (let i = 1; i <= (periodValueNum * 12); i++) {
        const prevRowData = finalMonthArr[i - 1]
        crrentDate = GetNextDate(prevRowData.date)
        currentAvBeforeWd = GetAvBeforeWd(i, crrentDate, prevRowData.date, prevRowData.avAfterWd)
        currentIntCredit = currentAvBeforeWd - prevRowData.avAfterWd
        currentCash = flagWithdrawInterest ? currentIntCredit : 0
        if (i == (periodValueNum * 12)) {
          currentCash = (currentAvBeforeWd - (flagWithdrawInterest ? currentIntCredit : 0)) + (flagWithdrawInterest ? currentIntCredit : 0)
          // currentCash = Number(amountTextNumber) + currentIntCredit
        }
        finalMonthArr.push({
          month: i,
          date: crrentDate,
          avBeforeWd: currentAvBeforeWd,
          intCredit: currentIntCredit,
          wd: flagWithdrawInterest ? currentIntCredit : 0,
          avAfterWd: ((currentAvBeforeWd - (flagWithdrawInterest ? currentIntCredit : 0))),
          cashFlow: currentCash,
          flagMax: false,
          flagMin: false
        })
        monthlyGraphDataArr.push({
          name: i,
          amount: Number(currentIntCredit?.toFixed(2))
        })
        monthlyLabels.push(`year ${i / 10}`)
        graphPoints.push(addCommas(currentIntCredit?.toFixed(2)))
      }
      let max = Math.max.apply(Math, finalMonthArr.map(function (o) { return o.intCredit }))
      let maxIndx = finalMonthArr.findIndex((obj) => obj.intCredit == max)
      let min = Math.min.apply(Math, finalMonthArr.slice(1, finalMonthArr?.length).map(function (o) { return o.intCredit }))
      let minIndx = finalMonthArr.findIndex((obj, i) => obj.intCredit == min)
      finalMonthArr[maxIndx].flagMax = true
      finalMonthArr[minIndx].flagMin = true
      setFinalMonthlyChartData(monthlyGraphDataArr);
      setMonthlyData(finalMonthArr)
    }

  }, [amountText, periodValue, flagWithdrawInterest, interstRate])

  function isThisDecimal(val) {
    return val % 1 !== 0
  }

  function daysBetweenDates(date1, date2) {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    const timeDiff = Math.abs(secondDate - firstDate);
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  const widrawLable = () => {
    return <span>Withdraw Interest?<span style={{ color: "red" }}>*</span></span>
  }

  const getMonthlySum = (row_name) => {
    let sum = monthlyData?.reduce((accumulator, currentObject) => {
      return accumulator + Number(currentObject[row_name])
    }, 0)
    return sum
  }

  console.log("finalMonthlyChartData", finalMonthlyChartData)

  return (
    <>
      {msg.status && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <img src={success_dialog} />
            <DialogContentText id="alert-dialog-description">
              <h4>Calculated Data Saved Successfully!</h4>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                navigate('/history')
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box className="img_div">
        <img src={Farmers_img} className="img" alt="img" />
        <span class="img_text1">Farmers Safeguard Plus®</span>
        <span class="img_text2">Multi-Year Guaranteed Annuity (MYGA)</span>
      </Box>

      <Box className="btn_div1">
        <Button
          variant="contained"
          onClick={() => {
            navigate("/history");
          }}
        >
          Back
        </Button>
      </Box>
      <Box className="main_container">
        <Box className="first_div">Calculator</Box>

        <Box className="third_box">
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            className="download_btn"
            onClick={() => {
              const graphX = finalChartData?.map((obj) => obj.name)
              const graphY = finalChartData?.map((obj) => obj.amount)
              const graphXMonth = finalMonthlyChartData?.map((obj) => obj.name)
              const graphYMonth = finalMonthlyChartData?.map((obj) => obj.amount)
              const d = new Date();
              SetFlagLoader(true);
              if (!calculation_id) {
                if (
                  final_value !== NaN &&
                  final_value !== 0 &&
                  age > 0 &&
                  amount != null &&
                  amount > 0 &&
                  name != null &&
                  year_list_id != null
                ) {
                  DownloadPdfAPI(
                    {
                      client: name,
                      preparedBy: prepardBy ? prepardBy :
                        state.user.first_name + " " + state.user.last_name,
                      age: Number(age),
                      state: state_Name,
                      Graph: { x: graphX, y: graphY },
                      amount: amount,
                      final_amount: surrenderCharge[surrenderCharge?.length - 1][3].toFixed(2),
                      period: periodValue,
                      interest_rate: interstRate,
                      pdf_array: surrenderCharge,
                      prepared: moment(d).format("DD/MM/YYYY h:mm"),
                      surrender_Charge: surrender_charge_array,
                      free_withdrawal_rider: freeWithdrawalRider,
                      enhanced_benefit_rider: enhancedBenefitRider,
                      withdrawal_graph: { x: graphXMonth, y: graphYMonth },
                      total_withdrawal_amount: getMonthlySum('wd')?.toFixed(2),
                      withdrawal_data: monthlyData,
                      is_withdraw_interest: flagWithdrawInterest,
                    },
                    (res) => {
                      SetFlagLoader(false);
                      if (res.data.status)
                        downloadBase64File(
                          res.data.data,
                          "Farmers_Safeguard_Plus_calculator.pdf"
                        );
                      else {
                        return setShowMsg({
                          status: false,
                          error: true,
                          msg: "Something Went Wrong!",
                        });
                      }
                    },
                    (err) => { SetFlagLoader(false); }
                  );
                } else if (name != null) {
                  SetFlagLoader(false);
                  return setShowMsg({
                    status: false,
                    error: true,
                    msg: "Client Name, Client Age,State, Amount and Period Value can't be empty",
                  });
                }
              } else {
                DownloadPdfAPI(
                  {
                    client: name,
                    agent: state.user.first_name + " " + state.user.last_name,
                    age: Number(age),
                    state: state_Name,
                    Graph: { x: graphX, y: graphY },
                    amount: amount,
                    final_amount: surrenderCharge[surrenderCharge?.length - 1][3].toFixed(2),
                    prepardBy: prepardBy,
                    period: periodValue,
                    interest_rate: interstRate,
                    pdf_array: surrenderCharge,
                    prepared: moment(d).format("DD/MM/YYYY h:mm"),
                    surrender_Charge: surrender_charge_array,
                    free_withdrawal_rider: freeWithdrawalRider,
                    enhanced_benefit_rider: enhancedBenefitRider,
                    withdrawal_graph: { x: graphXMonth, y: graphYMonth },
                    total_withdrawal_amount: getMonthlySum('wd')?.toFixed(2),
                    withdrawal_data: monthlyData,
                    is_withdraw_interest: flagWithdrawInterest,
                  },
                  (res) => {
                    SetFlagLoader(false);
                    let url =
                      "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf";
                    window.open(url, "_blank", "noopener,noreferrer");
                  },
                  (err) => { SetFlagLoader(false); }
                );
              }
            }}
            style={{ borderColor: "2px solid red" }}
          >
            Download
          </Button>
        </Box>

        <Box className="first_part">
          <Box className="first_box">
            {ShowMsg.error && (
              <Alert className="errCalculator" severity="error">
                {ShowMsg.msg}
              </Alert>
            )}
            {ShowMsg.status && (
              <Alert className="msgCalculator" severity="success">
                {ShowMsg.msg}
              </Alert>
            )}
            <Grid container>
              <Grid item xs={12} >
                <Grid container justifyContent={"space-between"}>
                  <Grid item xl={5.5} xs={12} mt={2}>
                    <TextField
                      fullWidth
                      label="Client Name"
                      id="standard-basic"
                      placeholder="Client Name"
                      value={name}
                      disabled={calculation_id !== null ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                        setShowMsg({
                          status: false,
                          error: false,
                          msg: "",
                        });
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xl={5.5} xs={12} mt={2}>
                    <TextField
                      fullWidth
                      className="not_required"
                      id="standard-basic"
                      placeholder="Prepared By"
                      label="Prepared By"
                      value={prepardBy}
                      disabled={calculation_id !== null ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        setPrepardBy(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} >
                <Grid container justifyContent={"space-between"}>
                  <Grid item xl={5.5} xs={12} mt={2}>
                    <TextField
                      fullWidth
                      type="number"
                      id="standard-basic"
                      placeholder="Client age"
                      label="Client age"
                      value={age}
                      disabled={calculation_id !== null ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        if (e.target.value > 0 && e.target.value <= 100) {
                          setAge(e.target.value);
                        } else {
                          // alert("Age should be a number between 1 and 100");
                          return setShowMsg({
                            status: false,
                            error: true,
                            msg: "Age should be a number between 1 and 100",
                          });
                        }
                        setShowMsg({
                          status: false,
                          error: false,
                          msg: "",
                        });
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xl={5.5} xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="combo-box-demo"
                        options={stateList}
                        value={stateList.find((s) => s.state_name == state_Name)}
                        disabled={calculation_id !== null ? true : false}
                        onChange={(e, v) => {
                          console.log("params.............", v.state_name);
                          setStateId(v.state_id);
                          setStateName(v.state_name);
                          setShowMsg({
                            status: false,
                            error: false,
                            msg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <FormControl
                  fullWidth
                  variant="standard"
                  className="form_control"

                // label="Amount"
                >
                  <InputLabel htmlFor="standard-adornment-amount">
                    Amount
                  </InputLabel>
                  <Input
                    type="text"
                    id="standard-adornment-amount"
                    value={amountText ? amountText : ""}
                    disabled={calculation_id !== null ? true : false}
                    onChange={(e) => {
                      e.target.value = e.target?.value?.replace(/,/gi, "");
                      setAmount(e.target.value);
                      e.target.value = removeNonNumeric(e.target.value);
                      setAmountText(addCommas(e.target.value));
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} mt={2}>
                <div className="period_text">
                  Period<span style={{ color: "red" }}>*</span>
                </div>
                <div className="btn_div">
                  {periodValueObj?.map((index, i) => {
                    return (
                      <>
                        <button
                          className="btn_for_Calaculator"
                          disabled={calculation_id !== null ? true : false}
                          onClick={() => {
                            if (amount !== null) {
                              let getYearSList = index.surrender_charge.map(
                                (index) => index.surrender_charge_data
                              );
                              setYear_list_id(index.year_list_id);
                              setSurrender_charge_array(getYearSList);
                              setPeriodValue(index.year_list_name);
                              setInterstRate1(index.interest_rate);
                              // if (!interstRate)
                              setInterstRate(index.interest_rate);
                            }
                          }}
                          style={
                            periodValue === index.year_list_name
                              ? {
                                backgroundColor: "rgb(217, 162, 46)",
                                color: "white",
                                margin: i == 0 ? "5px 5px 5px 0px" : "5px 5px 5px 5px"
                              }
                              : { backgroundColor: "rgb(241, 241, 241)", margin: i == 0 ? "5px 5px 5px 0px" : "5px 5px 5px 5px" }
                          }
                        >
                          {index.year_list_name} Year
                        </button>
                      </>
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12} mt={2}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="top"
                    control={<Checkbox
                      checked={flagWithdrawInterest}
                      value={flagWithdrawInterest}
                      checkedIcon={<img src={checkedIcon} />}
                      icon={<img src={unCheckedIcon} />}
                      onChange={(e) => { setFlagWithdrawInterest(e.target.checked) }}
                      sx={{ padding: "0px 9px" }}
                    />}
                    sx={{ marginLeft: 0 }}
                    label={widrawLable()}
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>
            </Grid>

          </Box>
          <Box className="rightbox">
            <Box className="rightfirstcomponent">
              <Box className="rider_header">
                <span>Rider</span>
              </Box>
              <Box className="check_box">
                <FormGroup className="fm1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setFreeWithdrawalRider(e.target.checked);
                        }}
                        disabled={calculation_id !== null ? true : false}
                        checked={freeWithdrawalRider ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                        checkedIcon={<img src={checkedIcon} />}
                        icon={<img src={unCheckedIcon} />}
                      />
                    }
                    label="5% Free Withdrawal Rider"
                  />
                </FormGroup>
                <FormGroup className="fm2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setEnhancedBenefitRider(e.target.checked);
                        }}
                        disabled={calculation_id !== null ? true : false}
                        checked={enhancedBenefitRider ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                        checkedIcon={<img src={checkedIcon} />}
                        icon={<img src={unCheckedIcon} />}
                      />
                    }
                    label="Enhanced Benefit Rider"
                  />
                </FormGroup>
              </Box>
            </Box>
            <Grid container justifyContent={"center"} rowGap={1.5} columnGap={1.1}>
              <Grid item xs={11.5} sx={{ backgroundColor: "#D9A22E", color: "white", textAlign: "center", padding: "10px 0", borderRadius: "5px", marginTop: "10px" }}>
                Annual Interest Rate: {(interstRate * 100).toFixed(2)}%
              </Grid>
              <Grid item xs={5.6} className="grayBox">
                <Grid container className="grayBoxContainer" alignItems={"center"} justifyContent={"center"} direction={"column"}>
                  <Grid item xs={6}>
                    <h2 style={{ color: "#D9A22E" }}>
                      ${surrenderCharge?.length == 0 ? 0 :
                        surrenderCharge[surrenderCharge?.length - 1][3] ? addCommas(surrenderCharge[surrenderCharge?.length - 1][3].toFixed(2)) : 0
                      }
                    </h2>
                  </Grid>
                  <Grid item xs={6} mt={1.3} textAlign={"center"}>
                    <b>Amount After {periodValue} Years</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.6} className="grayBox">
                <Grid container className="grayBoxContainer" alignItems={"center"} justifyContent={"center"} direction={"column"}>
                  <Grid item xs={6}>
                    <h2 style={{ color: "#D9A22E" }}>
                      ${(amount > 0 && amount != null && amount != "") ? (getMonthlySum('wd') ? addCommas(getMonthlySum('wd').toFixed(2)) : 0) : 0}
                    </h2>
                  </Grid>
                  <Grid item xs={6} mt={1.3} textAlign={"center"}>
                    <b>Total Withdrawal</b>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Divider
          component="div"
          role="presentation"
          style={{
            width: "90%",
            color: "rgb(217, 162, 46) !important",
            margin: "0 auto !important",
          }}
        ></Divider>
        {amount > 0 && periodValue ?
          <>
            {finalChartData?.length ?
              <>
                <h4 style={{ textAlign: "center" }}>Account Value Over the Years</h4>
                <div className="chart" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <LineChart
                    width={800}
                    height={300}
                    data={finalChartData}
                  >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="amount"
                      tickFormatter={tick => {
                        return tick.toLocaleString();
                      }}
                      domain={([dataMin, dataMax]) => { const absMax = Math.max(Math.abs(dataMin), Math.ceil(dataMax * 1.2)); return [0, absMax]; }} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Line
                      type="monotone"
                      dataKey="amount"
                      stroke="#D9A22E"
                    // dot={false}
                    />
                  </LineChart>
                </div>
              </>
              : ""}

            <Box className="con_main_box">
              <Grid container justifyContent={"center"} className="con_main">
                <Grid item>

                  {surrenderCharge?.length ?
                    <TableContainer>
                      <Table className="guaranteedTable">
                        <TableHead>
                          <TableRow className="guaranteedTableRow">

                            <TableCell className="blankCell" align="center" colSpan={4}></TableCell>
                            <TableCell className="guaranteedTableCell" sx={{ borderRadius: "10px" }} align="center" colSpan={5}>Guaranteed</TableCell>

                          </TableRow>
                          <TableRow className="guaranteedTableRow">
                            <TableCell className="guaranteedTableCell firstCell" align="center">Year</TableCell>
                            <TableCell className="guaranteedTableCell" align="center">Surrender Charge</TableCell>
                            <TableCell className="guaranteedTableCell lastCell" align="center">Age</TableCell>
                            <TableCell className="blankCell" align="center"></TableCell>
                            <TableCell className="guaranteedTableCell firstCell" align="center">Interest</TableCell>
                            <TableCell className="guaranteedTableCell" align="center">Interest Credit</TableCell>
                            <TableCell className="guaranteedTableCell" align="center">Interest WD</TableCell>
                            <TableCell className="guaranteedTableCell" align="center">Account Value</TableCell>
                            <TableCell className="guaranteedTableCell lastCell" align="center">Surrender Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {surrenderCharge.map((row, i) => (
                            <TableRow className="guaranteedTableBodyRow">
                              <TableCell align="center" className="firstCell">{i + 1}</TableCell>
                              <TableCell align="center">
                                {Number((surrender_charge_array[i] * 100).toFixed(2))}%
                              </TableCell>
                              <TableCell className="lastCell" align="center">{Number(age) + i + 1}</TableCell>
                              <TableCell className="blankCell" align="center"></TableCell>
                              <TableCell className="firstCell" align="center">{(row[0] * 100).toFixed(2)}%</TableCell>
                              <TableCell align="center">${addCommas(row[1]?.toFixed(2))}</TableCell>
                              <TableCell align="center">${addCommas(row[2]?.toFixed(2))}</TableCell>
                              <TableCell align="center">${addCommas(row[3]?.toFixed(2))}</TableCell>
                              <TableCell className="lastCell" align="center">${addCommas(row[4]?.toFixed(2))}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow className="guaranteedTableBodyRow">
                            <TableCell sx={{ borderRadius: "10px" }} align="center" colSpan={3}><b>Total / Ending</b></TableCell>
                            <TableCell className="blankCell" align="center"></TableCell>
                            <TableCell className="firstCell" align="center"></TableCell>
                            <TableCell align="center"><b>${addCommas(creditSum.toFixed(2))}</b></TableCell>
                            <TableCell align="center"><b>${addCommas(wdSum.toFixed(2))}</b></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center" className="lastCell"></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> :
                    ""}
                </Grid>
                {flagWithdrawInterest && finalMonthlyChartData?.length ?
                  <Grid item xs={12} textAlign={"center"}>
                    <h4>Monthly interest cash flow</h4>
                    <div className="chart" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <LineChart
                        width={800}
                        height={300}
                        data={finalMonthlyChartData}
                      >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="name" />
                        <YAxis dataKey="amount"
                          tickFormatter={tick => {
                            return tick.toLocaleString();
                          }}
                          domain={([dataMin, dataMax]) => { const absMax = Math.max(Math.abs(dataMin), Math.ceil(dataMax * 1.2)); return [0, absMax]; }} />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Line
                          type="monotone"
                          dataKey="amount"
                          stroke="#D9A22E"
                        // dot={false}
                        />
                      </LineChart>
                    </div>
                  </Grid>
                  : ""}
                <Grid item xs={10} mt={2}>
                  {flagWithdrawInterest && monthlyData?.length ?
                    <>
                      <TableContainer>
                        <Table className="guaranteedTable">
                          <TableHead>
                            <TableRow className="guaranteedTableRow">
                              <TableCell className="guaranteedTableCell" sx={{ borderRadius: "10px" }} align="center" colSpan={7}>Monthly Cash Flow</TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>

                      <TableContainer sx={{ maxHeight: "65vh" }}>
                        <Table className="guaranteedTable" stickyHeader>
                          <TableHead>
                            <TableRow className="guaranteedTableRow">
                              <TableCell className="guaranteedTableCell firstCell" align="center">Month</TableCell>
                              <TableCell className="guaranteedTableCell" align="center">Date</TableCell>
                              <TableCell className="guaranteedTableCell" align="center">AV Before WD</TableCell>
                              <TableCell className="guaranteedTableCell" align="center">Interest Credit</TableCell>
                              <TableCell className="guaranteedTableCell" align="center">Withdrawal</TableCell>
                              <TableCell className="guaranteedTableCell" align="center">AV After WD</TableCell>
                              <TableCell className="guaranteedTableCell lastCell" align="center">Cash Flow</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {monthlyData.map((row, i) => (
                              <TableRow className="guaranteedTableBodyRow">
                                <TableCell align="center" className="firstCell">{row.month}</TableCell>
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">${addCommas((row.avBeforeWd).toFixed(2))}</TableCell>
                                <TableCell align="center" sx={{ color: row.flagMin ? "#FFA500" : (row.flagMax ? "#078A4E" : "black") }} >
                                  ${addCommas((row.intCredit).toFixed(2))}
                                  {row.flagMin ?
                                    <Chip label="Min" size="small" className="minLabel" />
                                    :
                                    (row.flagMax ?
                                      <Chip label="Max" size="small" className="maxLabel" />
                                      : "")}
                                </TableCell>
                                <TableCell align="center">${addCommas(Number(row.wd).toFixed(2))}</TableCell>
                                <TableCell align="center">${addCommas(Number(row.avAfterWd).toFixed(2))}</TableCell>
                                <TableCell align="center" className="lastCell">${addCommas((row.cashFlow).toFixed(2))}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow className="guaranteedTableBodyRow" sx={{ position: "sticky", bottom: 0 }}>
                              <TableCell className="firstCell" align="center"><b>Total</b></TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center"><b>${addCommas(getMonthlySum('intCredit').toFixed(2))}</b></TableCell>
                              <TableCell align="center"><b>${addCommas(getMonthlySum('wd').toFixed(2))}</b></TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center" className="lastCell"></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                    :
                    ""}
                </Grid>
              </Grid>
              {monthlyData?.length ?
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  className="con_main"
                >
                  <Button
                    variant="contained"
                    className="savebtn"
                    sx={{
                      display: calculation_id !== null ? "none !important" : "block",
                    }}
                    onClick={() => {
                      SetFlagLoader(true);
                      if (
                        age > 0 &&
                        name !== "" &&
                        amount > 0 &&
                        amount != null &&
                        periodValue !== 0
                      ) {
                        NewSaveDataAPI(
                          {
                            name: name,
                            amount: Number(amount),
                            year_list_id: year_list_id,
                            state_id: state_id,
                            age: Number(age),
                            final_amount: final_value,
                            is_withdraw_interest: flagWithdrawInterest,
                            total_monthly_withdrawal_amount: getMonthlySum('wd').toFixed(2),
                            issued_at_date: getIssuedate(),
                            interest_rate: interstRate,
                            free_withdrawal_rider: freeWithdrawalRider,
                            enhanced_benefit_rider: enhancedBenefitRider,
                            prepared_by: prepardBy
                          },
                          (res) => {
                            if (res.data.status == true) {
                              SetFlagLoader(false);
                              setMsg(res.data);
                              setOpen(true);
                            } else {
                              SetFlagLoader(false);
                            }
                          },
                          (err) => {
                            SetFlagLoader(false);
                            return setShowMsg({
                              status: false,
                              error: true,
                              msg: err.message,
                            });
                          }
                        );
                      } else {
                        SetFlagLoader(false);
                        window.scrollTo(0, 0)
                        return setShowMsg({
                          status: false,
                          error: true,
                          msg: "Please fill all the mandatory fields",
                        });
                      }
                    }}
                  >
                    Save
                  </Button>
                </Grid>
                : ""}
            </Box>
          </>
          : ""
        }
      </Box>
      <Footer />
    </>
  );
};

export default Calculator;
